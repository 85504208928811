<template>
  <!--薪酬编辑-->
  <div>
    <div class="title">
      <div class="title_t">
        <span>薪酬编辑</span>
      </div>
      <div class="title_r" @click="returnPage">
        <span>返回</span>
      </div>
    </div>

    <el-form ref="form" :model="form" label-width="100px">

      <el-card shadow="never">
        <!-- 部门选择 -->
        <div>
          <el-form-item label="部门：">
            <el-radio v-model="radio" label="1">调度部</el-radio>
            <el-radio v-model="radio" label="2">渠道销售部</el-radio>
            <el-radio v-model="radio" label="3">市场部</el-radio>
            <el-radio v-model="radio" label="4">财务部</el-radio>
            <el-radio v-model="radio" label="5">自营司机</el-radio>
          </el-form-item>
        </div>

        <el-row :gutter="15" class="marginb">
          <div class="tou">
            <span>部门负责人</span>
            <span v-if="fuzerentext != ''">({{fuzerentext}})</span>
            <el-button type="primary" class="touleft" @click="selectfuzeren">选择</el-button>
            <el-select v-model="fuzeren" placeholder="请选择" v-if="isfuzeren === true" @change="changefuzeren" class="touleft">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="wei">
            <div class="div1kuai">
              <span class="div2xiaoshudao"></span>
              <span class="font1title">基础薪资</span>
              <!--<el-button type="primary" size="small" @click="addsalaryList()">-->
              <!--  添加基础字段-->
              <!--</el-button>-->
            </div>
            <el-row :gutter="15" class="div3">
              <el-col :span="12" v-for="(item,index) in form.basic_wage" :key="index" class="div4">
                <div class="div5">
                  <span v-if="index === 0" class="div6">{{item.label}}</span>
                  <span v-if="index === 1" class="div6">{{item.label}}</span>
                  <el-input v-if="index != 0 && index != 1" class="div7" clearable v-model="item.label" placeholder="请输入"></el-input>
                  <el-input placeholder="请输入" v-model="item.value" class="inputstyle">
                    <template slot="append">元</template>
                  </el-input>
                  <el-button v-if="index != 0 && index != 1" type="danger" @click="del(item,index)">删除</el-button>
                </div>
              </el-col>
            </el-row>

            <div class="div1kuai" v-if="radio == '1' || radio == '2'">
              <span class="div2xiaoshudao"></span>
              <span class="font1title">考核权重</span>
            </div>
            <el-row :gutter="15" class="div3" v-if="radio == '1' || radio == '2'">
              <el-col :span="12">
                <span class="div6">负责人占比</span>
                <el-input placeholder="请输入" v-model="value" class="inputstyle">
                  <template slot="append">%</template>
                </el-input>
              </el-col>
              <el-col :span="12">
                <span class="div6">其他占比</span>
                <el-input placeholder="请输入" v-model="value" class="inputstyle">
                  <template slot="append">%</template>
                </el-input>
              </el-col>
            </el-row>
          </div>
        </el-row>
        <el-row :gutter="15" v-if="radio == '1'" class="marginb">
          <div class="tou">
            部门整体考核
          </div>
          <div class="wei">
            <el-row :gutter="15" class="div3">
              <el-col :span="8">
                <span class="div6">毛利率</span>
                <el-input placeholder="请输入" v-model="value" class="inputstyle">
                  <template slot="append">%</template>
                </el-input>
              </el-col>
              <el-col :span="8">
                <span class="div6">合格奖励</span>
                <el-input placeholder="请输入" v-model="value" class="inputstyle">
                  <template slot="append">元</template>
                </el-input>
              </el-col>
              <el-col :span="8">
                <span class="div6">不合格扣款</span>
                <el-input placeholder="请输入" v-model="value" class="inputstyle">
                  <template slot="append">元</template>
                </el-input>
              </el-col>
            </el-row>
            <el-row :gutter="15" class="div3">
              <el-col :span="8">
                <span class="div6">投诉率</span>
                <el-input placeholder="请输入" v-model="value" class="inputstyle">
                  <template slot="append">%</template>
                </el-input>
              </el-col>
              <el-col :span="8">
                <span class="div6">合格奖励</span>
                <el-input placeholder="请输入" v-model="value" class="inputstyle">
                  <template slot="append">元</template>
                </el-input>
              </el-col>
              <el-col :span="8">
                <span class="div6">不合格扣款</span>
                <el-input placeholder="请输入" v-model="value" class="inputstyle">
                  <template slot="append">元</template>
                </el-input>
              </el-col>
            </el-row>
            <el-row :gutter="15" class="div3">
              <el-col :span="8">
                <span class="div6">催单率</span>
                <el-input placeholder="请输入" v-model="value" class="inputstyle">
                  <template slot="append">%</template>
                </el-input>
              </el-col>
              <el-col :span="8">
                <span class="div6">合格奖励</span>
                <el-input placeholder="请输入" v-model="value" class="inputstyle">
                  <template slot="append">元</template>
                </el-input>
              </el-col>
              <el-col :span="8">
                <span class="div6">不合格扣款</span>
                <el-input placeholder="请输入" v-model="value" class="inputstyle">
                  <template slot="append">元</template>
                </el-input>
              </el-col>
            </el-row>
          </div>
        </el-row>
        <el-row :gutter="15" v-if="radio == '1'" class="marginb">
          <div class="tou">
            小组长
          </div>
          <div class="wei">
            <div class="div1kuai">
              <span class="div2xiaoshudao"></span>
              <span class="font1title">基础薪资</span>
              <!--<el-button type="primary" size="small" @click="addsalaryList1()">-->
              <!--  添加基础字段-->
              <!--</el-button>-->
            </div>
            <el-row :gutter="15" class="div3">
              <el-col :span="12" v-for="(item,index) in form.zuzhang" :key="index" class="div4">
                <div class="div5">
                  <span v-if="index === 0" class="div6">{{item.label}}</span>
                  <span v-if="index === 1" class="div6">{{item.label}}</span>
                  <el-input v-if="index != 0 && index != 1" class="div7" clearable v-model="item.label" placeholder="请输入"></el-input>
                  <el-input placeholder="请输入" v-model="item.value" class="inputstyle">
                    <template slot="append">元</template>
                  </el-input>
                  <el-button v-if="index != 0 && index != 1" type="danger" @click="del1(item,index)">删除</el-button>
                </div>
              </el-col>
            </el-row>

            <div class="div1kuai">
              <span class="div2xiaoshudao"></span>
              <span class="font1title">考核权重</span>
            </div>
            <el-row :gutter="15" class="div3">
              <el-col :span="12">
                <span class="div6">负责人占比</span>
                <el-input placeholder="请输入" v-model="value" class="inputstyle">
                  <template slot="append">%</template>
                </el-input>
              </el-col>
              <el-col :span="12">
                <span class="div6">其他占比</span>
                <el-input placeholder="请输入" v-model="value" class="inputstyle">
                  <template slot="append">%</template>
                </el-input>
              </el-col>
            </el-row>
          </div>
        </el-row>
        <el-row :gutter="15" v-if="radio == '1'" class="marginb">
          <div class="tou">
            小组整体考核
          </div>
          <div class="wei">
            <el-row :gutter="15" class="div3">
              <el-col :span="8">
                <span class="div6">平均完成时效</span>
                <el-input placeholder="请输入" v-model="value" class="inputstyle">
                  <template slot="append">分钟</template>
                </el-input>
                <el-input placeholder="请输入" v-model="value" class="inputstyle">
                  <template slot="append">%</template>
                </el-input>
              </el-col>
              <el-col :span="8">
                <span class="div6">合格奖励</span>
                <el-input placeholder="请输入" v-model="value" class="inputstyle">
                  <template slot="append">元</template>
                </el-input>
              </el-col>
              <el-col :span="8">
                <span class="div6">不合格扣款</span>
                <el-input placeholder="请输入" v-model="value" class="inputstyle">
                  <template slot="append">元</template>
                </el-input>
              </el-col>
            </el-row>
            <el-row :gutter="15" class="div3">
              <el-col :span="8">
                <span class="div6">投诉率不高于</span>
                <el-input placeholder="请输入" v-model="value" class="inputstyle">
                  <template slot="append">%</template>
                </el-input>
              </el-col>
              <el-col :span="8">
                <span class="div6">合格奖励</span>
                <el-input placeholder="请输入" v-model="value" class="inputstyle">
                  <template slot="append">元</template>
                </el-input>
              </el-col>
              <el-col :span="8">
                <span class="div6">不合格扣款</span>
                <el-input placeholder="请输入" v-model="value" class="inputstyle">
                  <template slot="append">元</template>
                </el-input>
              </el-col>
            </el-row>
            <el-row :gutter="15" class="div3">
              <el-col :span="8">
                <span class="div6">催单率不高于</span>
                <el-input placeholder="请输入" v-model="value" class="inputstyle">
                  <template slot="append">%</template>
                </el-input>
              </el-col>
              <el-col :span="8">
                <span class="div6">合格奖励</span>
                <el-input placeholder="请输入" v-model="value" class="inputstyle">
                  <template slot="append">元</template>
                </el-input>
              </el-col>
              <el-col :span="8">
                <span class="div6">不合格扣款</span>
                <el-input placeholder="请输入" v-model="value" class="inputstyle">
                  <template slot="append">元</template>
                </el-input>
              </el-col>
            </el-row>
            <el-row :gutter="15" class="div3">
              <el-col :span="8">
                <span class="div6">溢价率不高于平均客单价</span>
                <el-input placeholder="请输入" v-model="value" class="inputstyle">
                  <template slot="append">%</template>
                </el-input>
              </el-col>
              <el-col :span="8">
                <span class="div6">合格奖励</span>
                <el-input placeholder="请输入" v-model="value" class="inputstyle">
                  <template slot="append">元</template>
                </el-input>
              </el-col>
              <el-col :span="8">
                <span class="div6">不合格扣款</span>
                <el-input placeholder="请输入" v-model="value" class="inputstyle">
                  <template slot="append">元</template>
                </el-input>
              </el-col>
            </el-row>
            <el-row :gutter="15" class="div3">
              <el-col :span="8">
                <span class="div6">拖车毛利率不低于</span>
                <el-input placeholder="请输入" v-model="value" class="inputstyle">
                  <template slot="append">%</template>
                </el-input>
              </el-col>
              <el-col :span="8">
                <span class="div6">合格奖励</span>
                <el-input placeholder="请输入" v-model="value" class="inputstyle">
                  <template slot="append">元</template>
                </el-input>
              </el-col>
              <el-col :span="8">
                <span class="div6">不合格扣款</span>
                <el-input placeholder="请输入" v-model="value" class="inputstyle">
                  <template slot="append">元</template>
                </el-input>
              </el-col>
            </el-row>
            <el-row :gutter="15" class="div3">
              <el-col :span="8">
                <span class="div6">搭电毛利率不低于</span>
                <el-input placeholder="请输入" v-model="value" class="inputstyle">
                  <template slot="append">%</template>
                </el-input>
              </el-col>
              <el-col :span="8">
                <span class="div6">合格奖励</span>
                <el-input placeholder="请输入" v-model="value" class="inputstyle">
                  <template slot="append">元</template>
                </el-input>
              </el-col>
              <el-col :span="8">
                <span class="div6">不合格扣款</span>
                <el-input placeholder="请输入" v-model="value" class="inputstyle">
                  <template slot="append">元</template>
                </el-input>
              </el-col>
            </el-row>
            <el-row :gutter="15" class="div3">
              <el-col :span="8">
                <span class="div6">困境亏损率</span>
                <el-input placeholder="请输入" v-model="value" class="inputstyle">
                  <template slot="append">%</template>
                </el-input>
              </el-col>
              <el-col :span="8">
                <span class="div6">合格奖励</span>
                <el-input placeholder="请输入" v-model="value" class="inputstyle">
                  <template slot="append">元</template>
                </el-input>
              </el-col>
              <el-col :span="8">
                <span class="div6">不合格扣款</span>
                <el-input placeholder="请输入" v-model="value" class="inputstyle">
                  <template slot="append">元</template>
                </el-input>
              </el-col>
            </el-row>
            <el-row :gutter="15" class="div3">
              <el-col :span="8">
                <span class="div6">其他毛利率</span>
                <el-input placeholder="请输入" v-model="value" class="inputstyle">
                  <template slot="append">%</template>
                </el-input>
              </el-col>
              <el-col :span="8">
                <span class="div6">合格奖励</span>
                <el-input placeholder="请输入" v-model="value" class="inputstyle">
                  <template slot="append">元</template>
                </el-input>
              </el-col>
              <el-col :span="8">
                <span class="div6">不合格扣款</span>
                <el-input placeholder="请输入" v-model="value" class="inputstyle">
                  <template slot="append">元</template>
                </el-input>
              </el-col>
            </el-row>
          </div>
        </el-row>

        <el-row :gutter="15" class="marginb" v-if="radio == '2'">
          <div class="tou">
            <span>月度营业额提成</span>
            <!--<el-button type="success" size="small" class="margin-l" @click="addsalaryList3()">-->
            <!--  添加-->
            <!--</el-button>-->
          </div>
          <div class="wei">
            <el-row :gutter="15" class="div3">
              <el-col :span="12" v-for="(item,index) in form.yuedu" :key="index" class="div4">
                <div class="div5">
                  <span v-if="index === 0" class="div6">{{item.label}}</span>
                  <span v-if="index === 1" class="div6">{{item.label}}</span>
                  <el-input v-if="index != 0 && index != 1" class="div7" clearable v-model="item.label" placeholder="请输入"></el-input>
                  <el-input placeholder="请输入" v-model="item.value" class="inputstyle">
                    <template slot="append">{{item.fuhao}}</template>
                  </el-input>
                  <el-button v-if="index != 0 && index != 1" type="danger" @click="del3(item,index)">删除</el-button>
                </div>
              </el-col>
            </el-row>
          </div>
        </el-row>
        <el-row :gutter="15" class="marginb" v-if="radio == '2'">
          <div class="tou">
            <span>季度营业额提成</span>
            <!--<el-button type="success" size="small" class="margin-l" @click="addsalaryList4()">-->
            <!--  添加-->
            <!--</el-button>-->
          </div>
          <div class="wei">
            <el-row :gutter="15" class="div3">
              <el-col :span="12" v-for="(item,index) in form.jidu" :key="index" class="div4">
                <div class="div5">
                  <span v-if="index === 0" class="div6">{{item.label}}</span>
                  <span v-if="index === 1" class="div6">{{item.label}}</span>
                  <el-input v-if="index != 0 && index != 1" class="div7" clearable v-model="item.label" placeholder="请输入"></el-input>
                  <el-input placeholder="请输入" v-model="item.value" class="inputstyle">
                    <template slot="append">{{item.fuhao}}</template>
                  </el-input>
                  <el-button v-if="index != 0 && index != 1" type="danger" @click="del4(item,index)">删除</el-button>
                </div>
              </el-col>
            </el-row>
          </div>
        </el-row>

        <!--市场部-->
        <el-row :gutter="15" v-if="radio == '3'" class="marginb">
          <div class="tou">
            部门整体考核
          </div>
          <div class="wei">
            <el-row :gutter="15" class="div3">
              <el-col :span="12">
                <span class="div6">一类服务商不低于</span>
                <el-input placeholder="请输入" v-model="value" class="inputstyle">
                  <template slot="append">家</template>
                </el-input>
              </el-col>
              <el-col :span="12">
                <span class="div6">不合格占比绩效薪资</span>
                <el-input placeholder="请输入" v-model="value" class="inputstyle">
                  <template slot="append">%</template>
                </el-input>
              </el-col>
            </el-row>
            <el-row :gutter="15" class="div3">
              <el-col :span="12">
                <span class="div6">二类服务商不低于</span>
                <el-input placeholder="请输入" v-model="value" class="inputstyle">
                  <template slot="append">家</template>
                </el-input>
              </el-col>
              <el-col :span="12">
                <span class="div6">不合格占比绩效薪资</span>
                <el-input placeholder="请输入" v-model="value" class="inputstyle">
                  <template slot="append">%</template>
                </el-input>
              </el-col>
            </el-row>
            <el-row :gutter="15" class="div3">
              <el-col :span="12">
                <span class="div6">企业司机不低于</span>
                <el-input placeholder="请输入" v-model="value" class="inputstyle">
                  <template slot="append">人</template>
                </el-input>
              </el-col>
              <el-col :span="12">
                <span class="div6">个体司机不低于</span>
                <el-input placeholder="请输入" v-model="value" class="inputstyle">
                  <template slot="append">人</template>
                </el-input>
              </el-col>
            </el-row>
            <el-row :gutter="15" class="div3">
              <el-col :span="12">
                <span class="div6">客单价环比下降</span>
                <el-input placeholder="请输入" v-model="value" class="inputstyle">
                  <template slot="append">%</template>
                </el-input>
              </el-col>
            </el-row>
            <el-row :gutter="15" class="div3">
              <el-col :span="12">
                <span class="div6">月度客单价支出</span>
                <el-input placeholder="请输入" v-model="value" class="inputstyle">
                  <template slot="append">元</template>
                </el-input>
              </el-col>
              <el-col :span="12">
                <span class="div6">季度客单价支出</span>
                <el-input placeholder="请输入" v-model="value" class="inputstyle">
                  <template slot="append">元</template>
                </el-input>
              </el-col>
            </el-row>
          </div>
        </el-row>

        <!--财务部-->
        <el-row :gutter="15" v-if="radio == '4'" class="marginb">
          <div class="tou">
            部门整体考核
          </div>
          <div class="wei">
            <el-row :gutter="15" class="div3">
              <el-col :span="12">
                <span class="div6">完成对账时间</span>
                <el-input placeholder="请输入" v-model="value" class="inputstyle">
                  <template slot="append">每月日</template>
                </el-input>
              </el-col>
              <el-col :span="12">
                <span class="div6">完成开票时间</span>
                <el-input placeholder="请输入" v-model="value" class="inputstyle">
                  <template slot="append">每月日</template>
                </el-input>
              </el-col>
            </el-row>
            <el-row :gutter="15" class="div3">
              <el-col :span="12">
                <span class="div6">完成回款时间</span>
                <el-input placeholder="请输入" v-model="value" class="inputstyle">
                  <template slot="append">次月日</template>
                </el-input>
              </el-col>
            </el-row>
          </div>
        </el-row>

        <!--车队-->
        <el-row :gutter="15" v-if="radio == '5'" class="marginb">
          <div class="tou">
            部门整体考核
          </div>
          <div class="wei">
            <el-row :gutter="15" class="div3">
              <el-col :span="12">
                <span class="div6">燃油金额高于平均值罚款</span>
                <el-input placeholder="请输入" v-model="value" class="inputstyle">
                  <template slot="append">元</template>
                </el-input>
              </el-col>
              <el-col :span="12">
                <span class="div6">燃油金额低于平均值奖励</span>
                <el-input placeholder="请输入" v-model="value" class="inputstyle">
                  <template slot="append">元</template>
                </el-input>
              </el-col>
            </el-row>
            <el-row :gutter="15" class="div3">
              <el-col :span="12">
                <span class="div6">客单燃油成本高于平均值罚款</span>
                <el-input placeholder="请输入" v-model="value" class="inputstyle">
                  <template slot="append">元</template>
                </el-input>
              </el-col>
              <el-col :span="12">
                <span class="div6">客单燃油成本低于平均值奖励</span>
                <el-input placeholder="请输入" v-model="value" class="inputstyle">
                  <template slot="append">元</template>
                </el-input>
              </el-col>
            </el-row>
            <el-row :gutter="15" class="div3">
              <el-col :span="12">
                <span class="div6">单张照片不合格扣款</span>
                <el-input placeholder="请输入" v-model="value" class="inputstyle">
                  <template slot="append">元</template>
                </el-input>
              </el-col>
              <el-col :span="12">
                <span class="div6">单张照片合格奖励</span>
                <el-input placeholder="请输入" v-model="value" class="inputstyle">
                  <template slot="append">元</template>
                </el-input>
              </el-col>
            </el-row>
            <el-row :gutter="15" class="div3">
              <el-col :span="12">
                <span class="div6">聚合成功奖励</span>
                <el-input placeholder="请输入" v-model="value" class="inputstyle">
                  <template slot="append">元</template>
                </el-input>
              </el-col>
              <el-col :span="12">
                <span class="div6">聚合失败扣减</span>
                <el-input placeholder="请输入" v-model="value" class="inputstyle">
                  <template slot="append">元</template>
                </el-input>
              </el-col>
            </el-row>
            <el-row :gutter="15" class="div3">
              <el-col :span="12">
                <span class="div6">项目提成按照营业额</span>
                <el-input placeholder="请输入" v-model="value" class="inputstyle">
                  <template slot="append">元</template>
                </el-input>
              </el-col>
              <el-col :span="12">
                <span class="div6">固定单量</span>
                <el-input placeholder="请输入" v-model="value" class="inputstyle">
                  <template slot="append">元</template>
                </el-input>
              </el-col>
            </el-row>
          </div>
        </el-row>

        <el-row :gutter="15" class="marginb">
          <div class="tou">
            员工
          </div>
          <div class="wei">
            <div class="div1kuai">
              <span class="div2xiaoshudao"></span>
              <span class="font1title">基础薪资</span>
              <!--<el-button type="primary" size="small" @click="addsalaryList2()">-->
              <!--  添加基础字段-->
              <!--</el-button>-->
            </div>
            <el-row :gutter="15" class="div3">
              <el-col :span="12" v-for="(item,index) in form.yuangong" :key="index" class="div4">
                <div class="div5">
                  <span v-if="index === 0" class="div6">{{item.label}}</span>
                  <span v-if="index === 1" class="div6">{{item.label}}</span>
                  <el-input v-if="index != 0 && index != 1" class="div7" clearable v-model="item.label" placeholder="请输入"></el-input>
                  <el-input placeholder="请输入" v-model="item.value" class="inputstyle">
                    <template slot="append">元</template>
                  </el-input>
                  <el-button v-if="index != 0 && index != 1" type="danger" @click="del2(item,index)">删除</el-button>
                </div>
              </el-col>
            </el-row>
          </div>
        </el-row>


      </el-card>


      <div style="margin-top: 30px; text-align: center">
        <el-button style="width: 200px !important" @click="returnPage"
          >取消</el-button
        >
        <el-button
          type="warning"
          style="width: 200px !important; margin-left: 20px"
          @click="save()"
          >提交</el-button
        >
      </div>
    </el-form>
  </div>
</template>

<script>

export default {
  name: "index",
  // 支柱道具属性
  props: {
    // 修改回显的数据
    data: Object,
    // 字典数据
    dict: Object,
  },
  // 组件生效
  components: {},
  // Data数据
  data() {
    return {
      // 表单数据
      form: Object.assign({}, this.data),
      isUpdate: false,

      //部门选择
      radio: "1",
      value:'',
      value1:'',

      //部门负责人
      fuzeren:'',
      options:[
        {
          label:'张三',
          value:'1'
        },
        {
          label:'李四',
          value:'2'
        },
        {
          label:'王五',
          value:'3'
        }
      ],
      isfuzeren:false,
      fuzerentext:'',

    };
  },
  // 计算属性 类似于 data 概念
  computed: {},
  // 监控 data 中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this 实例）
  created() {},
  // 生命周期 - 挂载完成（可以访问 DOM 元素）
  mounted() {
    if (this.data) {
      this.isUpdate = true;
    } else {
      this.form = {};
      this.isUpdate = false;
    }


    this.form = {
      basic_wage:[],
      zuzhang:[],
      yuangong:[],
      yuedu:[],
      jidu:[]
    }
    this.form.basic_wage = [
      {
        label: "基础工资",
        value: "",
      },
      {
        label: "奖金",
        value: "",
      },
    ];
    this.form.zuzhang = [
      {
        label: "基础工资",
        value: "",
      },
      {
        label: "奖金",
        value: "",
      },
    ];
    this.form.yuangong = [
      {
        label: "基础工资",
        value: "",
      },
      {
        label: "奖金",
        value: "",
      },
    ];
    this.form.yuedu = [
      {
        label: "月度营业额",
        fuhao:'元',
        value: "",
      },
      {
        label: "超出按照总营业额提成",
        fuhao:'%',
        value: "",
      },
    ];
    this.form.jidu = [
      {
        label: "季度营业额",
        fuhao:'元',
        value: "",
      },
      {
        label: "超出按照总营业额提成",
        fuhao:'%',
        value: "",
      },
    ];
    console.log(this.form)
  },
  // 方法集合
  methods: {
    // 返回上级页面
    returnPage() {
      this.$emit("showNewSalary", false);
    },

    //选择部门负责人
    selectfuzeren(){
      this.isfuzeren = true;
    },
    changefuzeren(value){
      console.log(value)
      this.isfuzeren = false;
    },

    //基础薪资添加基础字段
    addsalaryList() {
      //给数组追加
      this.form.basic_wage.push({
        label: "",
        value: "",
      });
    },
    //删除
    del(item, index) {
      this.form.basic_wage.splice(index, 1);
    },

    // 小组长
    addsalaryList1() {
      //给数组追加
      this.form.zuzhang.push({
        label: "",
        value: "",
      });
    },
    //删除
    del1(item, index) {
      this.form.zuzhang.splice(index, 1);
    },

    // 员工
    addsalaryList2() {
      //给数组追加
      this.form.yuangong.push({
        label: "",
        value: "",
      });
    },
    //删除
    del2(item, index) {
      this.form.yuangong.splice(index, 1);
    },

    // 月度
    addsalaryList3() {
      //给数组追加
      this.form.yuedu.push({
        label: "",
        fuhao:'元',
        value: "",
      });
    },
    //删除
    del3(item, index) {
      this.form.yuedu.splice(index, 1);
    },
    // 季度
    addsalaryList4() {
      //给数组追加
      this.form.jidu.push({
        label: "",
        fuhao:'元',
        value: "",
      });
    },
    //删除
    del4(item, index) {
      this.form.jidu.splice(index, 1);
    },



    //提成添加基础字段
    addCommissionList() {
      this.form.royalties.push({
        criterion: "",
        label: "",
        period: "",
        royalty_type: "",
        value: "",
      });
    },
    //删除
    CommissionListdel(item, index) {
      this.form.royalties.splice(index, 1);
    },

    save() {
      // 判断是创建还是编辑
      if (this.isUpdate === false) {
      } else {
      }
    },
  },
};
</script>

<style scoped lang="scss">
.hide_box /deep/ .el-upload--picture-card {
  display: none;
}
.tou{
  font-size: 18px;
  padding: 15px;
  background: #f0f1f3;
  border: 1px solid #dfe2e7;
  display: flex;
  align-items: center;
  .touleft{
    margin-left: 20px;
  }
}
.wei{
  padding: 15px;
  border: 1px solid #dfe2e7;
  border-top: none;
}
.div1kuai{
  display: flex;align-items: center;
}
.div2xiaoshudao{
  width: 5px;height: 19px;background: #ff9b05;display: inline-block;margin-right: 10px;
}
.font1title{
  font-size: 20px;font-weight: 700;color: #202033;margin-right: 20px;
}
.div3{
  margin-top: 15px;
}
.div4{
  margin-bottom: 15px;
}
.div5{
  display: flex;align-items: center;
}
.div6{
  margin-right: 10px;
}
.div7{
  margin-right: 20px;width: 300px;
}
.inputstyle{
  width: 300px!important;
}
.marginb{
  margin-bottom: 30px;
}
.margin-l{
  margin-left: 20px;
}
</style>
